import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">Contact Us</h1>
            <hr />
            <p>If you'd like to contact us then you can do that here.</p>
            <p>
              However, please note that we get a huge volume of emails these
              days so if we can’t get back to you quickly then don’t take it
              personally.
            </p>
            <p>
              <strong>We'll respond to you ASAP.</strong>
            </p>
            <blockquote>
              <p>
                Please do not try to sell us products or expect us to freely
                recommend your products.
              </p>
            </blockquote>
            <p>
              <strong>
                Make sure to connect with Chicken Yard on Facebook as
                well.
              </strong>
            </p>

            <em>
              Note: Our contact form is currently down due to email server
              maintenance issues.
            </em>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPage

const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
`
